import React from "react"

import useGlobalStyles from "../global-styles"
import { Box, Button } from "@mui/material"

const Home = ({
  filteredGames,
  renderDescription,
  renderFilters,
  renderProducts,
}) => {
  const globalClasses = useGlobalStyles()

  const handleButtonClick = () => {
    // This will open the user's default email client
    window.location.href = `mailto:info@langlearngames.com?subject=Your listing x [langlearngames]`
  }

  return (
    <div className={globalClasses.home}>
      <div
        style={{
          fontSize: 24,
          marginTop: 5,
          lineHeight: 1.2,
          marginLeft: 5,
        }}
      >
        {renderDescription()}
      </div>

      <div style={{ marginLeft: 3, marginTop: 2 }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          style={{
            marginTop: "15px",
            fontSize: 13,
            padding: `5px 15px`,
            backgroundColor: "#111",
            borderRadius: "10px",
          }}
        >
          + Add Game
        </Button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: 17,
            marginTop: -1,
          }}
        >
          🕹️
          <span>{`${filteredGames.length} ${
            filteredGames.length > 1 ? "games" : "game"
          }`}</span>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            marginBottom: 1,
          }}
        >
          {renderFilters()}
        </Box>
      </div>

      <div style={{ marginTop: -10 }}>{renderProducts()}</div>
    </div>
  )
}

export default Home
