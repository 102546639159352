import React, { useState } from "react"
import {
  Grid,
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material"
import { truncate } from "lodash"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/home"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { games, categories } from "../data/data"
import CloseIcon from "@mui/icons-material/Close"
import { Link } from "gatsby"

const IndexPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("")
  const [searchTerm, setSearchTerm] = useState("")

  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value)
  }

  const handleSearchChange = event => {
    setSearchTerm(event.target.value)
  }

  let filteredGames = games

  // Filter by category if selected
  if (selectedCategory) {
    filteredGames = filteredGames.filter(game =>
      game.categories.includes(selectedCategory)
    )
  }
  // Further filter by search term if provided
  if (searchTerm) {
    filteredGames = filteredGames.filter(
      game =>
        game.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        game.description.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  const renderProducts = () => {
    const getCategoryBadge = categoryId => {
      const category = categories[categoryId]
      return category ? (
        <span
          style={{
            backgroundColor: category.color,
            borderRadius: "6px",
            padding: "3px 8px",
            color: "white",
            fontSize: "12px",
          }}
        >
          {category.title}
        </span>
      ) : null
    }

    return (
      <Grid container spacing={3} style={{ marginBottom: 140 }}>
        {filteredGames.map(game => (
          <Grid item xs={12} sm={6} md={4} style={{ cursor: "pointer" }}>
            <Link
              key={game.id}
              href={`/${game.slug}`}
              style={{ color: "#111" }}
            >
              <Box
                sx={{
                  position: "relative",
                  borderRadius: "10px",
                  padding: { xs: "14px", md: "16px" },
                  transition: "all .1s ease-in-out",
                  alignItems: "center",
                  backgroundColor: "#faefe6",
                  border: "1px solid rgb(98, 41, 30, 0.1)",
                  "&:hover": {
                    border: "1px solid black",
                  },
                  "&:hover .iconOpacity": {
                    opacity: 1,
                  },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    opacity: 0.8,
                    transition: "opacity .2s",
                  }}
                  className="iconOpacity"
                >
                  <a
                    href={`${game.url}?utm_source=langlearngames.com&utm_medium=referral`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => {
                      e.stopPropagation() // This stops the event from propagating to parent elements
                    }}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <OpenInNewIcon style={{ fontSize: 22 }} />
                    </Box>
                  </a>
                </Box>

                {game.image_url ? (
                  // <center>
                  <img
                    src={game.image_url}
                    style={{
                      width: 80,
                      height: 80,
                      marginRight: "14px",
                      borderRadius: "8px",
                    }}
                    alt={game.slug}
                  />
                ) : (
                  // </center>
                  <Box
                    style={{
                      width: "100px",
                      marginRight: "14px",
                      height: "100px",
                    }}
                  >
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    />
                  </Box>
                )}
                <Box style={{ marginTop: -25 }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "21px",
                      display: "flex",
                      alignItems: "center",
                      marginTop: -10,
                    }}
                  >
                    {game.title}
                  </div>
                  <div style={{ fontSize: "17px", marginTop: "10px" }}>
                    {truncate(game.description, { length: 100 })}
                  </div>
                  <Box sx={{ mt: 1 }}>
                    {getCategoryBadge(game.categories[0])}
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    )
  }

  const renderFilters = () => {
    return (
      <Box
        sx={{
          my: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              fontSize: "14px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
                borderWidth: "2px",
              },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "& .MuiOutlinedInput-input": {
                  color: "black",
                },
                "& .MuiInputLabel-root": {
                  color: "black",
                },
              },
            },
            "& .MuiInputLabel-root": {
              borderRadius: "10px",
              fontSize: "14px",
              marginTop: "-7px",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              marginTop: "1px",
            },
            "& .MuiInputLabel-shrink": {
              marginTop: "1px",
            },
          }}
        >
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            onClose={() => {
              setTimeout(() => {
                document.activeElement.blur()
              }, 0)
            }}
            size="small"
            labelId="category-select-label"
            id="category-select"
            value={selectedCategory}
            label="Category"
            onChange={handleCategoryChange}
            sx={{
              borderRadius: "10px",
              "& .MuiSelect-select": {
                fontSize: "14px",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
                borderWidth: "2px",
              },
            }}
          >
            <MenuItem value="" style={{ color: "#777", fontSize: "14px" }}>
              Clear
            </MenuItem>
            {Object.values(categories)
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(category => (
                <MenuItem
                  key={category.id}
                  value={category.id}
                  sx={{ fontSize: "14px" }}
                >
                  {category.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          size="small"
          fullWidth
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: searchTerm && (
              <IconButton onClick={() => setSearchTerm("")} edge="end">
                <CloseIcon style={{ fontSize: 14 }} />
              </IconButton>
            ),
          }}
          sx={{
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              fontSize: "14px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
                borderWidth: "2px",
              },
            },
            "& .MuiInputLabel-root": { fontSize: "14px" },
          }}
        />
      </Box>
    )
  }

  return (
    <Layout>
      <Seo />
      <Home
        filteredGames={filteredGames}
        renderDescription={() => (
          <span>Explore the best language learning games.</span>
        )}
        renderProducts={renderProducts}
        renderFilters={renderFilters}
      />
    </Layout>
  )
}

export default IndexPage
